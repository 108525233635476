import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Avatar,
  Typography,
  Dialog,
  Button,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { CommonUtils } from "utils";
import { LangConstant } from "const";
import UserStatusJoin from "pages/UserManagement/components/UserStatusJoin";
import UserStatusActive from "pages/UserManagement/components/UserStatusActive";
import { useUserList } from "hooks";

const UserListDialog = ({
  open,
  unitId,
  departmentId,
  onClose,
  title,
  sinceTime,
  lastTime,
  joinStatus,
  includeUnit,
  includeDepartment,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const [dialogTitle, setDialogTitle] = useState("");
  const [countTitle, setCountTitle] = useState("");

  const { currentPageUsers, pagination, isLoading, handleChangePage, filterUsers, funcExportExcel } = useUserList({
    joinStatus: joinStatus,
    unitId: unitId,
    departmentId: departmentId,
    sinceTime: sinceTime,
    lastTime: lastTime,
    includeUnit: includeUnit !== null && includeUnit !== undefined ? includeUnit : true,
    includeDepartment: includeDepartment !== null && includeDepartment !== undefined ? includeDepartment : true,
  });

  const onExport = async () => {
    const response = await funcExportExcel();
    if (response) {
      const link = document.createElement("a");
      link.href = response;
      link.download = "Danh_sach_nguoi_su_dung.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    if (open) {
      if (joinStatus === 1) {
        if (Boolean(unitId) || Boolean(departmentId)) {
          setDialogTitle("Danh sách người sử dụng trong " + title);
          setCountTitle("Số người sử dụng trong " + title + ": ");
        } else {
          setDialogTitle("Danh sách người dùng đã tham gia");
          setCountTitle("Số người dùng đã tham gia " + title + ": ");
        }
      } else {
        setDialogTitle("Danh sách người dùng chưa tham gia");
        setCountTitle("Số người dùng chưa tham gia " + title + ": ");
      }
    }
  }, [open, title]);

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <Box className={classes.heading}>
        <Box className={classes.title}>{dialogTitle}</Box>
        <Button onClick={onClose}>
          <CloseOutlined fill="black" />
        </Button>
      </Box>

      <Box sx={{ padding: "16px" }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", gap: "2px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>{countTitle}</Typography>
            <Typography sx={{ fontSize: "16px" }}> {filterUsers.length} người</Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={onExport}>
            Xuất danh sách
          </Button>
        </Box>

        <TableContainer sx={{ height: "520px", marginTop: "8px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" width="5%">
                  <strong>STT</strong>
                </TableCell>
                <TableCell align="center" width="25%">
                  <strong>{getLabel("TXT_USER")}</strong>
                </TableCell>
                <TableCell align="center" width="13%">
                  <strong>{getLabel("TXT_UNIT")}</strong>
                </TableCell>
                <TableCell align="left" width="13%">
                  <strong>{getLabel("TXT_DEPARTMENT")}</strong>
                </TableCell>
                <TableCell align="center" width="13%">
                  <strong>{getLabel("OBJ_USER_DETAIL.activeStatus")}</strong>
                </TableCell>
                <TableCell align="center" width="13%">
                  <strong>{getLabel("OBJ_USER_DETAIL.joinStatus")}</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <small>{getLabel(LangConstant.TXT_LOADING)}</small>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {currentPageUsers.length > 0 ? (
                  currentPageUsers.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{(pagination.current - 1) * 10 + index + 1}</TableCell>
                      <TableCell align="center">
                        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                          <Avatar
                            sx={{ backgroundColor: CommonUtils.stringToColor(user.name) }}
                            alt={user.name}
                            src={user.image}
                            className={classes.userImage}
                          >
                            {user.name?.charAt(0) ? user.name?.charAt(0)?.toUpperCase() : null}
                          </Avatar>
                          <Typography className={classes.userName}>
                            {user.name} ({user.phone})
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="center">{user.unitName || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                      <TableCell align="left">{user.departmentName || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                      <TableCell align="left">
                        <UserStatusActive status={user.branchAccountStatus} />
                      </TableCell>
                      <TableCell align="left">
                        <UserStatusJoin user={user} enableAction={false} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <small>{getLabel(LangConstant.TXT_NOT_FOUND)}</small>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {pagination.total > 1 && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
            <Pagination
              count={pagination.total}
              onChange={handleChangePage}
              page={pagination.current}
              className={classes.pagination}
              color="primary"
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default UserListDialog;

const useStyles = makeStyles(() => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
  },

  userImage: {
    width: "30px",
    height: "30px",
    position: "static",
  },

  userName: {
    fontSize: "14px",
  },

  heading: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    backgroundColor: "#F2F6FC",
  },

  title: {
    width: "95%",
    height: "98%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "10%",
    fontSize: "20px",
    fontWeight: "bold",
    alignItems: "center",
  },
}));
