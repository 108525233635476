import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { ApiConstant, LangConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";

export default function useConferenceList() {
  const { t: getLabel } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = searchParams.get("page") || 1;
  const search = searchParams.get("search") || "";

  const [pagination, setPagination] = useState({ total: 1, current: parseInt(page) });
  const [searchInput, setSearchInput] = useState(search);

  const handleChangeSearchInput = event => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    const searchInputValue = event.target.value;
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("search", searchInputValue);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(state => ({ ...state, current: 1 }));
    setSearchInput(searchInputValue);
  };

  const handleChangePage = (_, page) => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", page);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(state => ({ ...state, current: parseInt(page) }));
  };

  const conferenceListQuery = useQuery({
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getServiceConfig, searchInput, pagination.current],
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryFn: async () => {
      const payload = [];
      const response = await ApiUtils.createApiWithToken(false).get(
        ApiConstant.GET_SERVER_CONFIG,
        HumpUtils.decamelizeKeys(payload),
      );
      return HumpUtils.camelizeKeys(response.data.data.valid_service);
    },
    onSuccess: response => {
      setPagination(state => ({ ...state, total: response?.data?.totalPages }));
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  return {
    pagination: pagination,
    searchInput: searchInput,
    conferences: conferenceListQuery?.data || [],
    handleChangeSearchInput: handleChangeSearchInput,
    handleChangePage: handleChangePage,
  };
}

