import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";
import { ApiConstant, AppConstant, LangConstant, SystemConstant, KeyConstant } from "const";
import { ConfirmDialog, PageTitle } from "components";
import { useDeleteUser, useImportUser, useUserList } from "hooks";
import { SystemUtils, StorageUtils } from "utils";
import {
  ConfirmActiveDialog,
  GeneratedPassword,
  Heading,
  ImportUserFailureDialog,
  ImportUserFromExcelDialog,
  ImportUserSuccessDialog,
  UserAction,
  UserInfoDialog,
  UserListTable,
} from "./components";
import { makeStyles } from "@mui/styles";
import UserRequestManagement from "pages/UserRequestManagement";

export const UserManagementContext = createContext({});

const UserManagement = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const isNotServiceAdmin = Number(StorageUtils.getStoreData(KeyConstant.KEY_ROLE)) !== SystemConstant.LOGIN_ROLE.SERVICE_ADMIN;

  const {
    form,
    userStates,
    userStatus,
    currentPageUsers,
    pagination,
    isLoading,
    handleChangeStateDropdown,
    handleChangeStatusDropdown,
    handleChangeSearchInput,
    handleChangePage,
    handleChangeUnitDropdown,
    handleChangeDepartmentDropdown,
  } = useUserList();

  const { importUsersAsync } = useImportUser();
  const { deleteUser } = useDeleteUser();

  const [selectedUser, setSelectedUser] = useState({ data: null, isOpen: false, isEdit: false });
  const [newPassword, setNewPassword] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [checkedUsers, setCheckedUsers] = useState({
    data: [],
    isDisable: false,
    isOpenDialog: false,
    isDelete: false,
  });
  const [importUserDialogConfig, setImportUserDialogConfig] = useState({ open: false });
  const [importUserStatusConfig, setImportUserStatusConfig] = useState({ open: false, status: null, fileLink: null });

  const [isOpenUserList, setIsOpenUserList] = useState(true);
  const [isOpenUserRequest, setIsOpenUserRequest] = useState(false);

  const handleOpenAction = (event, user) => {
    setAnchorEl(event.target);
    setSelectedUser(state => ({ ...state, data: user }));
  };

  const handleCloseAction = () => {
    setAnchorEl(null);
    setSelectedUser(state => ({ ...state, data: null, isOpen: false, isEdit: false }));
  };

  const handleCloseUserInfoDialog = () => {
    setSelectedUser(state => ({ ...state, data: null, isOpen: false, isEdit: false }));
  };

  const handleOpenGeneratedPasswordDialog = password => {
    setNewPassword(password);
  };

  const handleCloseGeneratedPasswordDialog = () => {
    setNewPassword(null);
    setSelectedUser(state => ({ ...state, data: null, isOpen: false, isEdit: false }));
  };

  const handleOpenUserInfo = () => {
    setSelectedUser(state => ({ ...state, isOpen: true, isEdit: false }));
  };

  const handleOpenEditUserInfo = () => {
    setSelectedUser(state => ({ ...state, isOpen: true, isEdit: true }));
  };

  const handleOpenCreateUser = () => {
    setSelectedUser(state => ({ ...state, data: null, isOpen: true, isEdit: true }));
  };

  const handleCheckboxChange = (isChecked, user) => {
    if (isChecked) {
      setCheckedUsers(state => ({ ...state, data: [...state.data, user] }));
    } else {
      setCheckedUsers(state => ({ ...state, data: state.data.filter(checkedUser => checkedUser.id !== user.id) }));
    }
  };

  const handleClickActiveButton = () => {
    setCheckedUsers(state => ({ ...state, isOpenDialog: true, isDisable: false }));
  };

  const handleClickInactiveButton = () => {
    setCheckedUsers(state => ({ ...state, isOpenDialog: true, isDisable: true }));
  };

  const handleClickDeleteButton = () => {
    setCheckedUsers(state => ({ ...state, isDelete: true }));
  };

  const handleCloseDeleteDialog = () => {
    setCheckedUsers(state => ({ ...state, isDelete: false }));
  };

  const handleCloseConfirmActiveDialog = () => {
    setCheckedUsers(state => ({ ...state, isOpenDialog: false }));
  };

  const handleClearCheckedUser = () => {
    setCheckedUsers(state => ({ ...state, data: [] }));
  };

  const handleCloseImportUserDialog = () => {
    setImportUserDialogConfig({ open: false });
  };

  const handleDownloadFile = () => {
    const timestamp = new Date().getTime();
    const link = document.createElement("a");
    link.setAttribute(
      "href",
      window.env.URL_TEMPLATE,
    );
    link.setAttribute("download", `Template_trios_import_account_2024_v1_${timestamp}.xlsx`);
    link.click();
    link.remove();
  };

  const handleSubmitFile = async file => {
    try {
      const maxFileNameLength = 255;
      const maxFileSize = 25 * 1024 * 1024; // 25 MB
      const acceptableFileExt = "xlsx";
      const fileArray = file.name.split(".");
      const fileExt = fileArray[fileArray.length - 1];
      if (
        file.name.length > maxFileNameLength ||
        file.size > maxFileSize ||
        fileExt !== acceptableFileExt ||
        // IMPORTANT: user có thể đổi đuôi file thành xlsx nhưng content bên trong có thể không phải là excel
        // => Check thêm cả mime_type của file tránh trường hợp gặp phải case này
        !AppConstant.IMPORT_USER_FILE_EXTENSIONS.includes(file.type)
      ) {
        setImportUserStatusConfig(state => ({ ...state, open: true, status: "error", fileLink: null }));
      } else {
        const response = await importUsersAsync(file);
        if (response.status === ApiConstant.STT_OK) {
          handleCloseImportUserDialog();
          setImportUserStatusConfig(state => ({ ...state, open: true, status: "success", fileLink: null }));
        } else if (response.status === ApiConstant.STT_BAD_REQUEST && response.data.type === "application/json") {
          setImportUserStatusConfig(state => ({ ...state, open: true, status: "error", fileLink: null }));
        } else if (response.status === ApiConstant.STT_BAD_REQUEST) {
          const url = URL.createObjectURL(response.data);
          setImportUserStatusConfig(state => ({ ...state, open: true, status: "error", fileLink: url }));
        } else {
          SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
        }
      }
    } catch (error) {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    }
  };

  const handleCloseImportUserStatusDialog = () => {
    setImportUserStatusConfig({ open: false, status: null, fileLink: null });
  };

  const handleClickDownloadErrorFile = () => {
    const timestamp = new Date().getTime();
    const link = document.createElement("a");
    link.setAttribute("href", importUserStatusConfig.fileLink);
    link.setAttribute("download", `Trios_import_account_${timestamp}.xlsx`);
    link.click();
    link.remove();
    handleCloseImportUserStatusDialog();
    handleCloseImportUserDialog();
  };

  const handleOpenImportDialog = () => {
    setImportUserDialogConfig({ open: true });
  };

  const handleDeleteUsers = () => {
    deleteUser({
      users: checkedUsers.data,
      onClose: () => {
        handleClearCheckedUser();
        handleCloseDeleteDialog();
      },
    });
  };

  const onClickUserList = () => {
    setIsOpenUserList(true);
    setIsOpenUserRequest(false);
  };

  const onClickUserRequest = () => {
    setIsOpenUserList(false);
    setIsOpenUserRequest(true);
  };

  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  const contextValue = {};

  return (
    <UserManagementContext.Provider value={contextValue}>
      <PageTitle>{titleObjectContent.userManagement}</PageTitle>
      {isNotServiceAdmin && (
        <Box sx={{ display: "flex", height: "40px" }}>
          <Box className={isOpenUserList ? classes.activeScreen : classes.inActiveScreen} onClick={onClickUserList}>
            <Typography sx={{ fontWeight: "bold" }}>Danh sách người dùng</Typography>
          </Box>
          <Box className={isOpenUserRequest ? classes.activeScreen : classes.inActiveScreen} onClick={onClickUserRequest}>
            <Typography sx={{ fontWeight: "bold" }}>Danh sách chờ phê duyệt</Typography>
          </Box>
        </Box>
      )}

      <Paper sx={{ px: 2, py: 4 }}>
        {isOpenUserList && (
          <>
            <Heading
              form={form}
              userStates={userStates}
              userStatus={userStatus}
              checkedUsers={checkedUsers}
              onChangeSearchInput={handleChangeSearchInput}
              onChangeStateDropdown={handleChangeStateDropdown}
              onChangeStatusDropdown={handleChangeStatusDropdown}
              onClickActiveButton={handleClickActiveButton}
              onClickInactiveButton={handleClickInactiveButton}
              onClickImportUser={handleOpenImportDialog}
              onClickCreateUser={handleOpenCreateUser}
              onClickDeleteButton={handleClickDeleteButton}
              handleChangeUnitDropdown={handleChangeUnitDropdown}
              handleChangeDepartmentDropdown={handleChangeDepartmentDropdown}
              isNotServiceAdmin={isNotServiceAdmin}
            />
            <UserListTable
              isLoading={isLoading}
              users={currentPageUsers}
              pagination={pagination}
              checkedUsers={checkedUsers.data}
              onClickAction={handleOpenAction}
              onChangePage={handleChangePage}
              onCheckBoxChange={handleCheckboxChange}
              isNotServiceAdmin={isNotServiceAdmin}
            />
            <UserAction
              data={selectedUser.data}
              anchorEl={anchorEl}
              open={Boolean(anchorEl && selectedUser.data)}
              onCloseAction={handleCloseAction}
              onClickUserInfo={handleOpenUserInfo}
              onClickUserEdit={handleOpenEditUserInfo}
              onOpenPasswordDialog={handleOpenGeneratedPasswordDialog}
              isNotServiceAdmin={isNotServiceAdmin}
            />
            <UserInfoDialog
              data={selectedUser.data}
              isOpen={selectedUser.isOpen}
              isEdit={selectedUser.isEdit}
              onClose={handleCloseUserInfoDialog}
              isNotServiceAdmin={isNotServiceAdmin}
            />
            <GeneratedPassword
              isOpen={Boolean(newPassword)}
              password={newPassword}
              onClose={handleCloseGeneratedPasswordDialog}
            />
            <ConfirmActiveDialog
              users={checkedUsers.data}
              isDisable={checkedUsers.isDisable}
              isShow={checkedUsers.isOpenDialog}
              onClose={handleCloseConfirmActiveDialog}
              onClear={handleClearCheckedUser}
            />
            <ImportUserFromExcelDialog
              open={Boolean(importUserDialogConfig.open)}
              onClose={handleCloseImportUserDialog}
              onDownload={handleDownloadFile}
              onSubmit={handleSubmitFile}
            />
            <ImportUserSuccessDialog
              open={Boolean(importUserStatusConfig.open && importUserStatusConfig.status === "success")}
              onClose={handleCloseImportUserStatusDialog}
            />
            <ImportUserFailureDialog
              open={Boolean(importUserStatusConfig.open && importUserStatusConfig.status === "error")}
              isFailureValidation={!Boolean(importUserStatusConfig.fileLink)}
              onClose={handleCloseImportUserStatusDialog}
              onClickDownload={handleClickDownloadErrorFile}
            />
            <ConfirmDialog
              open={checkedUsers.isDelete}
              title={getLabel(LangConstant.TXT_CONFIRM_DELETE_MEMBER)}
              onDeny={handleCloseDeleteDialog}
              onConfirm={handleDeleteUsers}
            />
          </>
        )}

        {isOpenUserRequest && <UserRequestManagement />}
      </Paper>
    </UserManagementContext.Provider>
  );
};

export default UserManagement;

const useStyles = makeStyles(theme => ({
  activeScreen: {
    width: "20%",
    display: "flex",
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "8px 8px 0 0",
  },
  inActiveScreen: {
    width: "20%",
    display: "flex",
    backgroundColor: "#EAEAEA",
    alignItems: "center",
    justifyContent: "center",
    height: "80%",
    marginTop: "8px",
    cursor: "pointer",
    "&:hover": {
      borderRadius: "8px 8px 0 0",
      backgroundColor: "cornflowerblue",
      color: "white",
    },
  },
}));