import { useTranslation } from "react-i18next";
import { Box, InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { debounce } from "lodash";

const Heading = ({
  searchInput,
  onChangeSearchInput,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const handleChangeSearchInput = debounce((event) => {
    onChangeSearchInput(event.target.value);
  }, 500);

  return (
    <Box className={classes.container}>
      <Box className={classes.filterContainer}>
        <TextField
          className={classes.textField}
          label={getLabel("P_SEARCH_APP")}
          variant="outlined"
          size="small"
          onChange={handleChangeSearchInput}
          defaultValue={searchInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Heading;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 8,
  },

  btnContainer: {
    display: "flex",
    gap: 8,
  },

  filterContainer: {
    display: "flex",
    gap: 8,
  },

  textField: {
    width: "600px",
  },

  button: {
    height: "40px",
  },
}));

