import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Clear } from "@mui/icons-material";
import { LangConstant } from "const";
import { CommonUtils } from "utils";

const EditAppDialog = ({ open, conference, onClose, onCreate, onUpdate }) => {
  const isEdit = Boolean(conference);
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const [form, setForm] = useState({
    conferenceId: conference?.id || "",
    name: conference?.name || "",
    description: conference?.description || "",
  });

  const handleChangeForm = event => {
    if (event.target.name === "name" || event.target.name === "description") {
      setForm(currentState => ({ ...currentState, [event.target.name]: CommonUtils.capitalizeFirstLetter(event.target.value) }));
    } else {
      setForm(currentState => ({ ...currentState, [event.target.name]: event.target.value }));
    }
  };

  const handleSubmit = () => {
    isEdit ? onUpdate(form) : onCreate(form);
  };

  useEffect(() => {
    if (open && conference) {
      setForm(currentState => ({
        ...currentState,
        conferenceId: conference?.id || "",
        name: conference?.name || "",
    description: conference?.description || "",
      }));
    } else {
      setForm(currentState => ({
        ...currentState,
        conferenceId: "",
        name: "",
        description: "",
      }));
    }
  }, [open, conference]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleName} sx={{ fontWeight: "bold" }}>
          {getLabel("TXT_EDIT_APP_INFO")}
        </Typography>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component="form" className={classes.form}>
          <TextField
            fullWidth
            label={getLabel("TXT_FOLDER_NAME") + " (*)"}
            placeholder={getLabel("TXT_FOLDER_NAME")}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={form.name}
            name="folderName"
            onChange={handleChangeForm}
          />
          <TextField
            fullWidth
            label={getLabel("TXT_DESCRIPTION") + " (*)"}
            placeholder={getLabel("TXT_DESCRIPTION")}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={form.description}
            name="description"
            onChange={handleChangeForm}
            multiline
            rows={4}
          />
          <Box className={classes.buttonContainer}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled
            >
              {getLabel(isEdit ? LangConstant.TXT_EDIT_INFO : LangConstant.L_CREATE)}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditAppDialog;

const useStyles = makeStyles(() => ({
  form: {
    padding: "8px 0",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },

  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },

  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
  },
}));
