import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Avatar,
  Typography,
  IconButton,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { CommonUtils } from "utils";
import { LangConstant, SystemConstant } from "const";
import theme from "theme/material";
import UserStatusJoin from "./UserStatusJoin";
import UserStatusActive from "./UserStatusActive";

const UserListTable = ({
  isLoading,
  users,
  pagination,
  checkedUsers,
  onCheckBoxChange,
  onClickAction,
  onChangePage,
  isNotServiceAdmin
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const handleCheckboxChange = (event, user) => {
    onCheckBoxChange(event.target.checked, user);
  };

  return (
    <Box>
      <Typography sx={{ fontSize: "larger", fontWeight: "bold" }}>
        Tổng số người dùng: {pagination.total_users}
      </Typography>
      <TableContainer sx={{ height: "520px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="left" width="5%"></TableCell>
              <TableCell align="left" width="25%">
                <strong>{getLabel("TXT_USER")}</strong>
              </TableCell>

              {isNotServiceAdmin ? (
                <>
                  <TableCell align="left" width="13%">
                    <strong>{getLabel("TXT_UNIT")}</strong>
                  </TableCell>
                  <TableCell align="left" width="13%">
                    <strong>{getLabel("TXT_DEPARTMENT")}</strong>
                  </TableCell>
                  <TableCell align="left" width="13%">
                    <strong>{getLabel("TXT_POSITION")}</strong>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell align="left" width="13%">
                    <strong>{getLabel("TXT_PHONENUMBER")}</strong>
                  </TableCell>
                  <TableCell align="left" width="13%">
                    <strong>{getLabel("TXT_PASSWORD")}</strong>
                  </TableCell>
                </>
              )}

              {isNotServiceAdmin && (
                <>
                  <TableCell align="left" width="13%">
                    <strong>{getLabel("OBJ_USER_DETAIL.activeStatus")}</strong>
                  </TableCell>
                  <TableCell align="left" width="13%">
                    <strong>{getLabel("OBJ_USER_DETAIL.joinStatus")}</strong>
                  </TableCell>
                </>
              )}
              <TableCell align="left" width="5%"></TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <small>{getLabel(LangConstant.TXT_LOADING)}</small>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {users.length > 0 ? (
                users.map((user, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">
                      <Checkbox
                        size="small"
                        onChange={event => handleCheckboxChange(event, user)}
                        checked={checkedUsers.map(user => user.id).includes(user.id)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
                        <Avatar
                          sx={{ backgroundColor: CommonUtils.stringToColor(user.name) }}
                          alt={user.name}
                          src={user.image}
                          className={classes.userImage}
                        >
                          {user.name?.charAt(0) ? user.name?.charAt(0)?.toUpperCase() : null}
                        </Avatar>
                        <Typography className={classes.userName}>
                          {user.name} {isNotServiceAdmin ? `(${user.phone})` : ""}
                        </Typography>
                      </Box>
                    </TableCell>
                    {isNotServiceAdmin ? (
                      <>
                        <TableCell align="left">{user.unitName || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                        <TableCell align="left">{user.departmentName || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                        <TableCell align="left">{user.positionName || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="left">{user.phone || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                        <TableCell align="left">{user.pin || getLabel(LangConstant.TXT_NOT_FOUND)}</TableCell>
                      </>
                    )}
                    {isNotServiceAdmin && (
                      <>
                        <TableCell align="left">
                          <UserStatusActive status={user.branchAccountStatus} />
                        </TableCell>
                        <TableCell align="left">
                          <UserStatusJoin user={user} isNotServiceAdmin={isNotServiceAdmin} />
                        </TableCell>
                      </>
                    )}
                    <TableCell align="left">
                      <IconButton onClick={event => onClickAction(event, user)}>
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <small>{getLabel(LangConstant.TXT_NOT_FOUND)}</small>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pagination.total > 1 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
          <Pagination
            count={pagination.total}
            onChange={onChangePage}
            page={pagination.current}
            className={classes.pagination}
            color="primary"
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  );
};

export default UserListTable;

const useStyles = makeStyles(() => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
  },

  userImage: {
    width: "30px",
    height: "30px",
    position: "static",
  },

  userName: {
    fontSize: "14px",
  },

  inviteAgain: {
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 600,
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginRight: 16,
  },
}));