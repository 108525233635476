import { useEffect, useMemo, useState, useRef } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import localforage from "localforage";
import { ApiConstant, KeyConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, CommonUtils, HumpUtils, StorageUtils, SystemUtils } from "utils";

export default function useUserList({
  joinStatus,
  unitId,
  departmentId,
  sinceTime,
  lastTime,
  includeUnit,
  includeDepartment,
} = {}) {
  const { t: getLabel } = useTranslation();
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false; // Đánh dấu component đã unmount
    };
  }, []);

  const userStates = {
    inactive: { title: getLabel("OBJ_USER_FILTER_STATUS.inactive"), state: 0, order: 3 },
    active: { title: getLabel("OBJ_USER_FILTER_STATUS.active"), state: 1, order: 1 },
    all: { title: getLabel("OBJ_USER_FILTER_STATUS.all"), state: 2, order: 0 },
    pending: { title: getLabel("OBJ_USER_FILTER_STATUS.pending"), state: 3, order: 2 },
  };

  const userStatus = {
    inactive: { title: getLabel(LangConstant.L_INACTIVE_USER), status: 0, order: 2 },
    active: { title: getLabel(LangConstant.L_ACTIVE_USER), status: 1, order: 1 },
    all: { title: getLabel("OBJ_USER_FILTER_STATUS.all"), status: 2, order: 0 },
  };

  const maxRowPerPage = 10;

  const location = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const page = searchParams.get("page") || 1;
  const state = searchParams.get("state") || userStates.all.state;
  const status = searchParams.get("status") || userStatus.all.status;
  const search = searchParams.get("search") || "";
  const formUnitId = searchParams.get("unitId") || 0;
  const formDepartmentId = searchParams.get("departmentId") || 0;

  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({
    state: parseInt(state),
    search: search,
    status: parseInt(status),
    unitId: formUnitId,
    departmentId: formDepartmentId,
  });
  const [pagination, setPagination] = useState(parseInt(page));

  const handleGetUserWithAvatar = async user => {
    try {
      // Cache avatar người dùng bằng IndexedDB localforage
      if (!user.avatarId) return { ...user, image: null };
      const userAvatarKey = "trios_cached_user_avatar_blob_";
      const localAvatar = await localforage.getItem(userAvatarKey + user.avatarId);
      if (localAvatar) return { ...user, image: URL.createObjectURL(localAvatar) };
      const currentDomain = StorageUtils.getStoreData(KeyConstant.KEY_CURRENT_DOMAIN);
      let url = "";
      if (Boolean(currentDomain) && currentDomain !== "") {
        url = "https://" + currentDomain + "/dev/v1?";
        const data = {
          attachment_id: user.avatarId,
          access_token: StorageUtils.getStoreData(KeyConstant.KEY_ACCESS_TOKEN),
        };
        const searchParams = new URLSearchParams(data);
        url += searchParams.toString();
      }
      new Promise(() => {
        const avatar = ApiUtils.apiGetAvatar(user, true);
        localforage.setItem(userAvatarKey + user.avatarId, avatar);
      });

      return { ...user, image: url };
    } catch (error) {
      console.error(error);
      return { ...user, image: null };
    }
  };

  const userListQuery = useQuery({
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    queryKey: [ApiConstant.REACT_QUERY_KEYS.getUserList],
    queryFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const apiPayload = HumpUtils.decamelizeKeys({
        branchId: branchId,
        isIncludeUnit: true,
        isIncludeDepartment: true,
        isIncludePosition: true,
      });
      if (Boolean(unitId) && unitId !== -1) {
        apiPayload.unit_id = unitId;
      }
      if (Boolean(departmentId)) {
        apiPayload.department_id = departmentId;
      }
      if (Boolean(sinceTime)) {
        apiPayload.since_time = sinceTime;
      }
      if (Boolean(lastTime)) {
        apiPayload.last_time = lastTime;
      }
      if (joinStatus) {
        apiPayload.account_join_status = joinStatus;
      }
      if (includeUnit !== null && includeUnit !== undefined) {
        apiPayload.is_include_unit = includeUnit;
      }
      if (includeDepartment !== null && includeDepartment !== undefined) {
        apiPayload.is_include_department = includeDepartment;
      }

      let userListResponse = await ApiUtils.createApiWithToken(false).get(ApiConstant.GET_LIST_USERS, apiPayload);
      if (isMountedRef.current) {
        userListResponse = userListResponse.data;
        if (userListResponse.status === ApiConstant.STT_OK && userListResponse.data) {
          setPagination(1);
          const users = HumpUtils.camelizeKeys(userListResponse.data);
          const userListWithAvatar = users.map(user => handleGetUserWithAvatar(user));
          return Promise.all(userListWithAvatar);
        }
        throw userListResponse;
      }
      return [];
    },
    onSuccess: users => {
      if (isMountedRef.current) {
        const currentTotalUsers = users.length;
        const isRemember = StorageUtils.getStoreData(KeyConstant.KEY_REMEMBER_KEY);
        const currentBranchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
        const currentBranchList = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_LIST);
        const newBranchList = currentBranchList.map(branch => {
          if (branch.id !== currentBranchId) return branch;
          return { ...branch, existedAccount: currentTotalUsers };
        });
        StorageUtils.storeData(KeyConstant.KEY_BRANCH_LIST, newBranchList, isRemember);
        storageBotAccount(users);
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const exportExcel = useMutation({
    mutationFn: async () => {
      const branchId = StorageUtils.getStoreData(KeyConstant.KEY_BRANCH_ID);
      const apiPayload = HumpUtils.decamelizeKeys({
        branchId: branchId,
        isIncludeUnit: true,
        isIncludeDepartment: true,
        isIncludePosition: true,
        exportF: 1,
      });
      if (Boolean(unitId)) {
        apiPayload.unit_id = unitId;
      }
      if (Boolean(departmentId)) {
        apiPayload.department_id = departmentId;
      }
      if (Boolean(sinceTime)) {
        apiPayload.since_time = sinceTime;
      }
      if (Boolean(lastTime)) {
        apiPayload.last_time = lastTime;
      }
      if (joinStatus) {
        apiPayload.account_join_status = joinStatus;
      }
      if (includeUnit !== null && includeUnit !== undefined) {
        apiPayload.is_include_unit = includeUnit;
      }
      if (includeDepartment !== null && includeDepartment !== undefined) {
        apiPayload.is_include_department = includeDepartment;
      }

      const response = await ApiUtils.createApiWithToken(false, { ...ApiUtils.API_CONFIG, responseType: "blob" }).get(
        ApiConstant.GET_LIST_USERS,
        HumpUtils.decamelizeKeys(apiPayload),
      );
      return URL.createObjectURL(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async response => { },
    onError: error => {
      console.log(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const handleChangeStateDropdown = async event => {
    SystemUtils.openCircleLoading();
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("state", event.target.value);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setForm(form => ({ ...form, state: parseInt(event.target.value) }));
    setPagination(1);
    await CommonUtils.delay();
    SystemUtils.openCircleLoading(false);
  };

  const handleChangeStatusDropdown = async event => {
    SystemUtils.openCircleLoading();
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("status", event.target.value);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setForm(form => ({ ...form, status: parseInt(event.target.value) }));
    setPagination(1);
    await CommonUtils.delay();
    SystemUtils.openCircleLoading(false);
  };

  const handleChangeSearchInput = async event => {
    SystemUtils.openCircleLoading();
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("search", event.target.value);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setForm(form => ({ ...form, search: event.target.value }));
    setPagination(1);
    await CommonUtils.delay();
    SystemUtils.openCircleLoading(false);
  };

  const handleChangeUnitDropdown = async event => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("unitId", event.target.value);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setForm(form => ({ ...form, unitId: event.target.value, departmentId: 0 }));
    setPagination(1);
  };

  const handleChangeDepartmentDropdown = async event => {
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", "1");
    newUrlSearchParams.set("departmentId", event.target.value);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setForm(form => ({ ...form, departmentId: event.target.value }));
    setPagination(1);
  };

  const handleChangePage = async (_, page) => {
    SystemUtils.openCircleLoading();
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", page);
    history.push(location.pathname + "?" + newUrlSearchParams.toString());
    setPagination(page);
    await CommonUtils.delay();
    SystemUtils.openCircleLoading(false);
  };

  const handleChangePageWithStatusActive = async (_, page) => {
    SystemUtils.openCircleLoading();
    const newUrlSearchParams = new URLSearchParams(searchParams);
    newUrlSearchParams.set("page", page);
    setForm(form => ({ ...form, status: parseInt(1) }));
    setPagination(page);
    await CommonUtils.delay();
    SystemUtils.openCircleLoading(false);
  };

  const filterUsers = users.filter(user => {
    let stateCondition = false;
    switch (form.state) {
      case userStates.inactive.state: {
        if (user.state === 1 && user.branchAccountState === 3) stateCondition = true;
        break;
      }
      case userStates.active.state: {
        if (user.state === 1 && user.branchAccountState === 1) stateCondition = true;
        break;
      }
      case userStates.all.state: {
        if (user.state !== 0 && user.branchAccountState !== 0) stateCondition = true;
        break;
      }
      case userStates.pending.state: {
        if (user.state === 3) stateCondition = true;
        break;
      }
      default: {
        stateCondition = false;
      }
    }

    let statusCondition = false;
    switch (form.status) {
      case userStatus.inactive.status: {
        if (user.branchAccountStatus === 0) statusCondition = true;
        break;
      }
      case userStatus.active.status: {
        if (user.branchAccountStatus === 1) statusCondition = true;
        break;
      }
      case userStatus.all.status: {
        statusCondition = true;
        break;
      }
      default: {
        statusCondition = false;
      }
    }

    let unitCondition = false;
    if (form.unitId === 0) {
      unitCondition = true;
    } else if (form.unitId === -1) {
      if (!user.unitId) unitCondition = true;
    } else {
      if (user.unitId === form.unitId) unitCondition = true;
    }

    let departmentCondition = false;
    if (form.departmentId === 0) {
      departmentCondition = true;
    } else if (form.departmentId === -1) {
      if (!user.departmentId) departmentCondition = true;
    } else {
      if (user.departmentId === form.departmentId) departmentCondition = true;
    }

    const searchValue = CommonUtils.removeVietnameseTones(form.search.toLowerCase());
    const userName = CommonUtils.removeVietnameseTones(user.name?.toLowerCase() || "");
    const phone = user.phone || "";
    const phoneWithoutVNCountryCode = phone.replace("+84", "0");

    return (
      stateCondition &&
      statusCondition &&
      unitCondition &&
      departmentCondition &&
      Boolean(
        !CommonUtils.isJson(user.name) &&
        (searchValue === "" ||
          userName.includes(searchValue) ||
          phone.includes(searchValue) ||
          phoneWithoutVNCountryCode.includes(searchValue)),
      )
    );
  });

  const storageBotAccount = users => {
    users.forEach(data => {
      if (data.phone === SystemConstant.BOT_ACCOUNT_PHONE) {
        StorageUtils.storeData(KeyConstant.KEY_BOT_ACCOUNT_ID, data.accountId, true);
      }
    });
  };

  const paginationConfigs = { current: pagination, total: Math.ceil(filterUsers.length / maxRowPerPage), total_users: filterUsers.length };

  const funcExportExcel = async () => {
    return exportExcel.mutateAsync();
  };

  useEffect(() => {
    if (userListQuery.data) setUsers(userListQuery.data);
  }, [userListQuery.data]);

  return {
    form: form,
    setForm: setForm,
    userStates: userStates,
    userStatus: userStatus,
    pagination: paginationConfigs,
    totalUsers: users,
    filterUsers: filterUsers,
    currentPageUsers: CommonUtils.paginateFromArray(filterUsers, pagination, maxRowPerPage),
    isLoading: userListQuery.isLoading,
    handleChangeStateDropdown: handleChangeStateDropdown,
    handleChangeStatusDropdown: handleChangeStatusDropdown,
    handleChangePageWithStatusActive,
    handleChangeUnitDropdown: handleChangeUnitDropdown,
    handleChangeDepartmentDropdown: handleChangeDepartmentDropdown,
    handleChangeSearchInput: debounce(handleChangeSearchInput, 500),
    handleChangePage: handleChangePage,
    funcExportExcel,
  };
}
