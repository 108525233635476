import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { ApiConstant, LangConstant, SystemConstant } from "const";
import { ApiUtils, HumpUtils, SystemUtils } from "utils";
import useInvalidateQueries from "hooks/common/useInvalidateQueries";
import qs from "qs";

export default function useUpdateRoleAccount() {
  const { t: getLabel } = useTranslation();
  const conferenceError = getLabel(LangConstant.OBJ_ROLE_ACCOUNT_ERROR, { returnObjects: true });

  const { invalidateServiceQueries } = useInvalidateQueries();

  const updateRoleAccountMutation = useMutation({
    mutationFn: async data => {
      const payload = {
        serviceId: data.serviceId,
        accountIds: data.accountIds,
      };
      const response = await ApiUtils.createApiWithToken(false).post(
        ApiConstant.UPDATE_SERVICE_ADMIN,
        qs.stringify(HumpUtils.decamelizeKeys(payload)),
      );
      return HumpUtils.camelizeKeys(response.data);
    },
    onMutate: () => {
      SystemUtils.openCircleLoading();
    },
    onSettled: () => {
      SystemUtils.openCircleLoading(false);
    },
    onSuccess: async (response, { onClose }) => {
      if (response.status === ApiConstant.STT_OK) {
        await invalidateServiceQueries();
        await ApiUtils.apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.EDIT_ROLE_ACCOUNT);
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ROLE_ACCOUNT_SUCCESS), "success");
        onClose();
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.CONFERENCE_ERROR.MISSING_PARAMETERS
      ) {
        SystemUtils.appendNotification(
          getLabel(conferenceError[SystemConstant.CONFERENCE_ERROR.MISSING_PARAMETERS]),
          "error",
        );
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.CONFERENCE_ERROR.IN_WRONG_FORMAT
      ) {
        SystemUtils.appendNotification(getLabel(conferenceError[SystemConstant.CONFERENCE_ERROR.IN_WRONG_FORMAT]), "error");
      } else if (
        response.status === ApiConstant.STT_BAD_REQUEST &&
        parseInt(response.error) === SystemConstant.CONFERENCE_ERROR.DUPLICATED
      ) {
        SystemUtils.appendNotification(getLabel(conferenceError[SystemConstant.CONFERENCE_ERROR.DUPLICATED]), "error");
      } else {
        SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
      }
    },
    onError: error => {
      console.error(error);
      SystemUtils.appendNotification(getLabel(LangConstant.TXT_UPDATE_ERROR), "error");
    },
  });

  const updateRoleAccount = payload => {
    updateRoleAccountMutation.mutate(payload);
  };

  return { updateRoleAccount };
}