import React, { useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Checkbox } from "@mui/material";
import { RemoveCircleOutline } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const UserTable = ({ users, selectedUsers = [], onActionClick, actionType, loadMore, hasMore }) => {
  const observer = useRef(null);
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const lastUserRef = useCallback(
    (node) => {
      if (!node || !hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            loadMore();
          }
        },
        { threshold: 1.0 }
      );

      observer.current.observe(node);
    },
    [hasMore, loadMore]
  );

  return (
    <Box sx={{ flex: 1, border: "1px solid #ddd", borderRadius: "5px", padding: 1 }}>
      <TableContainer sx={{ maxHeight: 400, overflowY: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.titleCell} sx={{ width: "5%" }} align="center">{getLabel("TXT_STT")}</TableCell>
              <TableCell className={classes.titleCell} sx={{ width: "50%" }}>{getLabel("TXT_FULL_NAME")}</TableCell>
              <TableCell className={classes.titleCell} sx={{ width: "40%" }}>{getLabel("TXT_PHONENUMBER")}</TableCell>
              <TableCell className={classes.titleCell} sx={{ width: "5%" }} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              users.map((user, index) => (
                <TableRow key={user.id} ref={index === users.length - 1 ? lastUserRef : null}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell align="center">
                    {actionType === "add" ? (
                      <Checkbox
                        size="small"
                        sx={{ width: 24, height: 24 }}
                        onChange={() => onActionClick(user)}
                        checked={selectedUsers.some((selected) => selected.id === user.id)}
                      />
                    ) : (
                      <IconButton color="error" onClick={() => onActionClick(user)} sx={{ width: 24, height: 24 }}>
                        <RemoveCircleOutline fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <i>{getLabel("TXT_DATA_NOT_FOUND")}</i>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserTable;

const useStyles = makeStyles(() => ({
  titleCell: {
    fontWeight: "bold",
    backgroundColor: "#F1F1F1"
  },
}));
