import React, { Fragment, useState, useEffect } from "react";
import { Box, FormControl, InputLabel, Select, Typography, MenuItem } from "@mui/material";
import { CalendarMonthOutlined, ArrowDropDownOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { CommonUtils, DatetimeUtils } from "utils";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useStatisticMessageGet } from "hooks";
import SelectDateTime from "./SelectDateTime";

const MessageStatistic = ({ unitList }) => {
  const classes = useStyles();
  const { getStatisticMessage } = useStatisticMessageGet();

  const [unitId, setUnitId] = useState(0);
  const [labelCalendar, setLabelCalendar] = useState("Hôm nay");
  const [totalMessage, setTotalMessage] = useState(0);
  const [dataSet, setDataSet] = useState("");
  const [sinceTime, setSinceTime] = useState(CommonUtils.getStartToday());
  const [lastTime, setLastTime] = useState(null);

  const [openDateTime, setOpenDateTime] = useState(false);

  const onChangeUnit = e => {
    setUnitId(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {};
      if (unitId === 0) {
        payload.include_unit_id = 0;
      } else {
        payload.include_unit_id = 1;
      }
      if (unitId !== 0 && unitId !== -1) {
        payload.unit_id = unitId;
      }
      if (sinceTime) {
        payload.since_time = sinceTime;
      }
      if (lastTime) {
        payload.last_time = lastTime;
      }

      const response = await getStatisticMessage(payload);
      if (response) {
        const responseData = response.data.sort((a, b) => {
          const dateA = new Date(a.date.split("/").reverse().join("-"));
          const dateB = new Date(b.date.split("/").reverse().join("-"));
          return dateA - dateB;
        });
        const label = responseData.map(item => item.date);
        const data = responseData.map(item => item.message_send);
        setTotalMessage(data.reduce((sum, element) => sum + element, 0));

        setDataSet({
          labels: label,
          datasets: [
            {
              label: "Số tin nhắn",
              data: data,
              backgroundColor: ["#357AF6"],
            },
          ],
        });
      }
    };

    fetchData();
  }, [unitId, sinceTime, lastTime]);

  return (
    <Fragment>
      <Box className={classes.heading}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}> Tổng quan tin nhắn </Typography>
        <Box sx={{ display: "flex", gap: "12px", position: "relative" }}>
          <FormControl sx={{ ml: 1, width: 200 }}>
            <InputLabel>Phân loại</InputLabel>
            <Select size="small" onChange={onChangeUnit} value={unitId} label="Phân loại">
              {unitList.map(data => (
                <MenuItem key={data.unitId} value={data.unitId}>
                  {data.unitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box
            className={classes.calendar}
            onClick={() => {
              setOpenDateTime(!openDateTime);
            }}
          >
            <CalendarMonthOutlined />
            <Typography> {labelCalendar} </Typography>
            <ArrowDropDownOutlined />
          </Box>
          <Box sx={{ position: "absolute", zIndex: 4, top: "42px", right: "0px" }}>
            <SelectDateTime
              open={openDateTime}
              setLabel={setLabelCalendar}
              onClose={() => setOpenDateTime(false)}
              setSinceTime={setSinceTime}
              setLastTime={setLastTime}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.chartParent}>
        <Box className={classes.chartChild}>
          <Typography className={classes.chartLabel}>
            Tổng số lượng tin nhắn đã gửi thành công:
            <Typography sx={{ marginLeft: "8px", fontSize: "20px", fontWeight: "bold", color: "cornflowerblue" }}>
              {totalMessage} tin nhắn
            </Typography>
          </Typography>
          <Box sx={{ width: "100%", height: "90%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {Boolean(dataSet) && <Bar data={dataSet} skipNull />}
          </Box>
        </Box>
      </Box>
      <Typography sx={{ color: "#A8A8A8" }}>
        *Dữ liệu cập nhật mới nhất vào {DatetimeUtils.convertMillisecondsToDateTime(Date.now())}
      </Typography>
    </Fragment>
  );
};

export default MessageStatistic;

const useStyles = makeStyles(() => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    borderBottom: "1px solid #DFDFDF",
  },

  calendar: {
    minWidth: "200px",
    height: "40px",
    border: "1px solid #DFDFDF",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
    paddingRight: "4px",
    justifyContent: "space-between",
    cursor: "pointer",
    maxWidth: "250px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },

  chartParent: {
    height: 550,
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },

  chartChild: {
    height: "90%",
    width: "90%",
    border: "1px solid #DFDFDF",
    borderRadius: "12px",
    padding: "16px",
  },

  chartLabel: {
    fontWeight: "bold",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
  },

  textInnerChart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "40%",
  },
}));
