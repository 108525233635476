import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant, SystemConstant } from "const";
import { ReInviteIcon } from "theme/images";
import { useInviteUser } from "hooks";

const UserStatusJoin = ({ user, enableAction, isNotServiceAdmin }) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const { inviteUser } = useInviteUser();

  const status = useMemo(() => {
    if (!user) return SystemConstant.USER_STATUS.inactive;
    if (user.state === 1 && user.branchAccountState === 3) return SystemConstant.USER_STATUS.pending;
    if (user.state === 1 && user.branchAccountState === 1) return SystemConstant.USER_STATUS.joined;
    if (user.state === 3) return SystemConstant.USER_STATUS.pending;
    return SystemConstant.USER_STATUS.inactive;
  }, [user]);

  const StatusLabel = () => {
    const statusMapping = {
      [SystemConstant.USER_STATUS.inactive]: {
        label: LangConstant.L_REMOVE_BUTTON,
        color: "error",
      },
      [SystemConstant.USER_STATUS.joined]: {
        label: LangConstant.L_JOINED_STATUS,
        color: "success",
      },
      [SystemConstant.USER_STATUS.pending]: {
        label: LangConstant.L_PENDING_STATUS,
        color: "warning",
      },
    };

    const { label, color } = statusMapping[status] || {
      label: LangConstant.L_REFUSED_STATUS,
      color: "error",
    };

    return <Chip label={getLabel(label)} color={color} variant="outlined" className={classes.status} />;
  };

  const handleInviteUser = () => {
    inviteUser({
      phone: user.phone,
    });
  };

  return (
    <Box className={classes.container}>
      <StatusLabel />
      {enableAction !== false && isNotServiceAdmin ? (
        <IconButton
          className={classes.iconButton}
          variant="contained"
          size="small"
          title={getLabel(LangConstant.TXT_INVITE_AGAIN)}
          onClick={handleInviteUser}
        >
          <ReInviteIcon width={28} height={28} />
        </IconButton>
      ) : (
        ""
      )}
    </Box>
  );
};

export default UserStatusJoin;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  status: {
    cursor: "default",
    width: "120px",
    position: "static",
  },

  iconButton: {
    border: "1px solid #D4D5D8",
    width: "32px",
    height: "32px",
  },
}));
