import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { LangConstant, KeyConstant, SystemConstant } from "const";
import { PageTitle } from "components";
import { ChangePasswordForm, ChangeLanguageForm, ChangeMode, ServerInformation } from "./components";
import { StorageUtils } from "utils";

const Settings = () => {
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });
  return (
    <Fragment>
      <PageTitle>{titleObjectContent.settings}</PageTitle>
      <Box>
        <ChangePasswordForm />
        {Number(StorageUtils.getStoreData(KeyConstant.KEY_ROLE)) !== SystemConstant.LOGIN_ROLE.SERVICE_ADMIN && (
          <>
            <ChangeLanguageForm />
            <ChangeMode />
            <ServerInformation />
          </>
        )}
      </Box>
    </Fragment>
  );
};

export default Settings;
