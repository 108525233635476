import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";
import { useUnitList } from "hooks";
import { PageTitle } from "components";
import { UserStatistic, MessageStatistic, CallStatistic, GroupStatistic } from "./components";

const Statistic = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();
  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });

  const { totalUnitList } = useUnitList();

  return (
    <Fragment>
      <PageTitle>{getLabel(titleObjectContent.statistic)}</PageTitle>

      <Paper sx={{ px: 2, py: 4, paddingTop: "20px", paddingBottom: "8px", marginBottom: "16px" }}>
        <UserStatistic
          unitList={[
            { unitId: 0, unitName: "Tất cả" },
            { unitId: -1, unitName: "Khác" },
          ].concat(
            totalUnitList.sort((a, b) => {
              return a.unitName.localeCompare(b.unitName, "vi");
            }),
          )}
        />
      </Paper>

      <Paper sx={{ px: 2, py: 4, paddingTop: "20px", paddingBottom: "8px", marginBottom: "16px" }}>
        <MessageStatistic
          unitList={[
            { unitId: 0, unitName: "Tất cả" },
            { unitId: -1, unitName: "Khác" },
          ].concat(
            totalUnitList.sort((a, b) => {
              return a.unitName.localeCompare(b.unitName, "vi");
            }),
          )}
        />
      </Paper>

      <Paper sx={{ px: 2, py: 4, paddingTop: "20px", paddingBottom: "8px", marginBottom: "16px" }}>
        <CallStatistic
          unitList={[
            { unitId: 0, unitName: "Tất cả" },
            { unitId: -1, unitName: "Khác" },
          ].concat(
            totalUnitList.sort((a, b) => {
              return a.unitName.localeCompare(b.unitName, "vi");
            }),
          )}
        />
      </Paper>

      <Paper sx={{ px: 2, py: 4, paddingTop: "20px", paddingBottom: "8px", marginBottom: "16px" }}>
        <GroupStatistic />
      </Paper>
    </Fragment>
  );
};

export default Statistic;

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 8,
  },

  btnContainer: {
    display: "flex",
    gap: 8,
  },

  filterContainer: {
    display: "flex",
    gap: 8,
  },

  textField: {
    width: "400px",
  },

  button: {
    height: "40px",
  },
}));
