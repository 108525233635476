import Cookies from "js-cookie";
import { KeyConstant, SystemConstant } from "const";
import { getStoreData, storeData } from "./storage.utils";
import { openCircleLoading } from "./system.utils";
import { apiCreateUserActivitiesLog } from "./api.utils";

export const hasLogin = () => {
  return Boolean(getStoreData(KeyConstant.KEY_PRE_AUTH) || getStoreData(KeyConstant.KEY_ACCESS_TOKEN));
};

export const handleLogout = (reload = true, writeLog = true) => {
  if (writeLog) {
    openCircleLoading();
    apiCreateUserActivitiesLog(SystemConstant.LOGGER_ACTION_TYPES.LOGOUT).then(() => {
      const isRemember = getStoreData(KeyConstant.KEY_REMEMBER_KEY);
      const smartOtpData = getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
      Object.keys(Cookies.get()).forEach(cookieName => {
        Cookies.remove(cookieName);
      });
      localStorage.clear();
      const newSmartOtpData = {};
      for (const key in smartOtpData) {
        if (Object.hasOwnProperty.call(smartOtpData, key)) {
          const configs = smartOtpData[key];
          newSmartOtpData[key] = { ...configs, code: null };
        }
      }
      storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, newSmartOtpData, isRemember);
      openCircleLoading(false);
      if (reload) window.location.reload();
      else openCircleLoading(false);
    });
  } else {
    const isRemember = getStoreData(KeyConstant.KEY_REMEMBER_KEY);
    const smartOtpData = getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    });
    localStorage.clear();
    const newSmartOtpData = {};
    for (const key in smartOtpData) {
      if (Object.hasOwnProperty.call(smartOtpData, key)) {
        const configs = smartOtpData[key];
        newSmartOtpData[key] = { ...configs, code: null };
      }
    }
    storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, newSmartOtpData, isRemember);
    if (reload) window.location.reload();
  }
};

export const getCurrentSmartOtpConfigs = () => {
  if (!getStoreData(KeyConstant.KEY_PRE_AUTH)) handleLogout(false, false);
  const username = getStoreData(KeyConstant.KEY_PRE_AUTH)?.username || null;
  const smartOtpList = getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
  return username && smartOtpList && smartOtpList[username] ? smartOtpList[username] : null;
};

export const setCurrentSmartOtpConfigs = configs => {
  if (!getStoreData(KeyConstant.KEY_PRE_AUTH)) handleLogout(false);
  const username = getStoreData(KeyConstant.KEY_PRE_AUTH)?.username || null;
  const smartOtpList = getStoreData(KeyConstant.KEY_SMART_OTP_CONFIGS);
  if (username && smartOtpList && smartOtpList[username]) {
    const isRemember = getStoreData(KeyConstant.KEY_REMEMBER_KEY);
    smartOtpList[username] = { ...configs };
    storeData(KeyConstant.KEY_SMART_OTP_CONFIGS, smartOtpList, isRemember);
  }
};

export const hasVerifiedSmartOTP = () => {
  const smartOtpConf = getCurrentSmartOtpConfigs();
  if (!smartOtpConf) return false;
  return Boolean(smartOtpConf.code);
};
