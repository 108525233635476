import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, InputAdornment, Typography } from "@mui/material";
import { Clear, Search } from "@mui/icons-material";
import UserTable from "./UserTable";
import { useTranslation } from "react-i18next";

const ActionDialog = ({
  form, open, onClose, checkedUsers,
  handleUpdateAccountRole, conference,
  onChangeSearchInput, onChangePage,
  currentPageUsers, pagination, isEdit
}) => {
  const [searchTerm, setSearchTerm] = useState(form.search || "");
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState(checkedUsers);
  const { t: getLabel } = useTranslation();

  const hasMore = useMemo(() => pagination.total > pagination.current, [pagination]);

  useEffect(() => {
    setIsLoading(true);
    onChangePage(null, pagination.current).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setSearchTerm(form.search || "");
  }, [form.search]);

  useEffect(() => {
    setLoadedUsers((prevUsers) => {
      const newUsers = currentPageUsers.filter(
        (user) => !prevUsers.some((prevUser) => prevUser.id === user.id)
      );
      return [...prevUsers, ...newUsers];
    });
  }, [currentPageUsers]);

  useEffect(() => {
    setSelectedUsers(checkedUsers);
  }, [checkedUsers]);

  const loadMore = useCallback(() => {
    if (!hasMore || isLoading) return;
    setIsLoading(true);
    onChangePage(null, pagination.current + 1).finally(() => {
      setIsLoading(false);
    });
  }, [hasMore, isLoading, onChangePage, pagination.current]);

  const handleAddUser = (user) => {
    setSelectedUsers((prev) => {
      const isSelected = prev.some((u) => u.id === user.id);
      return isSelected ? prev.filter((u) => u.id !== user.id) : [...prev, user];
    });
  };

  const handleRemoveUser = (user) => {
    setSelectedUsers((prev) => prev.filter((u) => u.id !== user.id));
  };

  const handleSubmit = () => {
    if (selectedUsers.length > 0) {
      handleUpdateAccountRole({
        serviceId: conference.serviceId,
        accountIds: selectedUsers.map((user) => user.accountId),
      });
    }
  };

  const filteredUsers = useMemo(() => {
    if (!searchTerm) return loadedUsers;
    return loadedUsers.filter(
      (user) =>
        user?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user?.phone?.includes(searchTerm)
    );
  }, [loadedUsers, searchTerm]);

  return (
    <Dialog open={open} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>{getLabel(isEdit ? "TXT_EDIT_ADMINISTRATOR" : "TXT_ADD_ADMINISTRATOR")}</Typography>
        <IconButton onClick={onClose}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1 }}>
          {getLabel("TXT_TOTAL_ADMINS")}: {selectedUsers.length}
        </Typography>

        <Box sx={{ padding: "5px 10px", mb: 2 }}>
          <TextField
            fullWidth
            label={getLabel("TXT_ADMINISTRATOR") + " (*)"}
            variant="outlined"
            defaultValue={form.search}
            onChange={onChangeSearchInput}
            placeholder={getLabel("P_ADD_NEW_ADMIN")}
            InputLabelProps={{
              shrink: true, // Giúp label luôn ở trên
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box sx={{ display: "flex", gap: 2 }}>
          <UserTable
            title="Danh sách người dùng"
            users={filteredUsers}
            selectedUsers={selectedUsers}
            onActionClick={handleAddUser}
            actionType="add"
            loadMore={loadMore}
            hasMore={hasMore}
          />
          <UserTable
            title="Danh sách quản trị viên"
            users={selectedUsers}
            onActionClick={handleRemoveUser}
            actionType="remove"
          />
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <Button fullWidth variant="contained" color="primary" disabled={selectedUsers.length === 0} onClick={handleSubmit}>
            {getLabel("L_UPDATE")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ActionDialog;
