import React, { Fragment, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CalendarMonthOutlined, ArrowDropDownOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { CommonUtils, DatetimeUtils } from "utils";
import "chart.js/auto";
import { useStatisticGroupGet } from "hooks";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import SelectDateTime from "./SelectDateTime";

const GroupStatistic = () => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation();

  const { getStatisticGroup } = useStatisticGroupGet();

  const [labelCalendar, setLabelCalendar] = useState("Hôm nay");
  const [sinceTime, setSinceTime] = useState(CommonUtils.getStartToday());
  const [lastTime, setLastTime] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataGroupStatistic, setDataGroupStatistic] = useState([]);

  const [openDateTime, setOpenDateTime] = useState(false);

  const maxRowPerPage = 10;

  const onChangePage = (e, page) => {
    setPage(page);
  };

  const onExport = async () => {
    const payload = {
      export_f: 1,
    };
    if (sinceTime) {
      payload.since_time = sinceTime;
    }
    if (lastTime) {
      payload.last_time = lastTime;
    }

    const response = await getStatisticGroup(payload);
    if (response) {
      const link = document.createElement("a");
      link.href = response;
      link.download = "Tong_quan_hoat_dong.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        paging: 1,
        limit: maxRowPerPage,
        offset: (page - 1) * maxRowPerPage,
      };
      if (sinceTime) {
        payload.since_time = sinceTime;
      }
      if (lastTime) {
        payload.last_time = lastTime;
      }

      const response = await getStatisticGroup(payload);
      if (response) {
        setDataGroupStatistic(response.data.data);
        setTotalPage(response.data.total_pages);
      }
    };

    fetchData();
  }, [page, sinceTime, lastTime]);

  return (
    <Fragment>
      <Box className={classes.heading}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}> Tổng quan hoạt động Kênh/Nhóm </Typography>
        <Box sx={{ display: "flex", gap: "12px", position: "relative" }}>
          <Box
            className={classes.calendar}
            onClick={() => {
              setOpenDateTime(!openDateTime);
            }}
          >
            <CalendarMonthOutlined />
            <Typography> {labelCalendar} </Typography>
            <ArrowDropDownOutlined />
          </Box>

          <Box sx={{ position: "absolute", zIndex: 4, top: "42px", right: "166px" }}>
            <SelectDateTime
              open={openDateTime}
              setLabel={setLabelCalendar}
              onClose={() => setOpenDateTime(false)}
              setSinceTime={setSinceTime}
              setLastTime={setLastTime}
            />
          </Box>

          <Button variant="contained" color="primary" onClick={onExport}>
            Xuất danh sách
          </Button>
        </Box>
      </Box>

      <Box className={classes.dataTable}>
        <TableContainer sx={{ my: 4 }}>
          <Table stickyHeader sx={{ minWidth: 650 }} className={classes.boxTable}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "5%", fontWeight: "bold" }} align="center">
                  STT
                </TableCell>
                <TableCell sx={{ width: "15%", fontWeight: "bold" }} align="center">
                  Loại
                </TableCell>
                <TableCell sx={{ width: "20%", fontWeight: "bold" }} align="center">
                  Tên
                </TableCell>
                <TableCell sx={{ width: "20%", fontWeight: "bold" }} align="center">
                  Số lượng thành viên
                </TableCell>
                <TableCell sx={{ width: "20%", fontWeight: "bold" }} align="center">
                  Tổng tin nhắn đã gửi
                </TableCell>
                <TableCell sx={{ width: "20%", fontWeight: "bold" }} align="center">
                  Hoạt động cuối cùng
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataGroupStatistic?.length > 0 ? (
                dataGroupStatistic.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.name} align="center">
                      {maxRowPerPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell className={classes.name} align="center">
                      {SystemConstant.GROUP_TYPE_NAME[data.group_type]}
                    </TableCell>
                    <TableCell className={classes.name} align="center">
                      {data.group_name}
                    </TableCell>
                    <TableCell className={classes.name} align="center">
                      {data.total_account}
                    </TableCell>
                    <TableCell className={classes.name} align="center">
                      {data.total_message}
                    </TableCell>
                    <TableCell className={classes.name} align="center">
                      {DatetimeUtils.convertMillisecondsToDateTime(data.last_active)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <small>
                      <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                    </small>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataGroupStatistic.length > 0 && (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
            <Pagination
              count={totalPage}
              onChange={onChangePage}
              page={page}
              className={classes.pagination}
              color="primary"
              variant="outlined"
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Box>
        )}
      </Box>
      <Typography sx={{ color: "#A8A8A8" }}>
        *Dữ liệu cập nhật mới nhất vào {DatetimeUtils.convertMillisecondsToDateTime(Date.now())}
      </Typography>
    </Fragment>
  );
};

export default GroupStatistic;

const useStyles = makeStyles(() => ({
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    borderBottom: "1px solid #DFDFDF",
  },

  calendar: {
    minWidth: "200px",
    height: "40px",
    border: "1px solid #DFDFDF",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px",
    paddingRight: "4px",
    justifyContent: "space-between",
    cursor: "pointer",
    maxWidth: "250px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },

  dataTable: {},

  pagination: {
    display: "flex",
    justifyContent: "center",
  },

  boxTable: {
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },

  name: {
    whiteSpace: "wrap",
    overflowWrap: "break-word",
  },
}));
