import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Backdrop,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add, Feed, Search, UploadFile } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LangConstant, SystemConstant } from "const";
import { useUnitList, useDepartmentList } from "hooks";

const Heading = ({
  form,
  userStates,
  userStatus,
  checkedUsers,
  onChangeSearchInput,
  onChangeStateDropdown,
  onChangeStatusDropdown,
  onClickActiveButton,
  onClickInactiveButton,
  onClickDeleteButton,
  onClickImportUser,
  onClickCreateUser,
  handleChangeUnitDropdown,
  handleChangeDepartmentDropdown,
  isNotServiceAdmin,
}) => {
  const classes = useStyles();
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  const { totalUnitList } = useUnitList();
  const { departmentList, handleToggleShowDepartment } = useDepartmentList();

  const [openDial, setOpenDial] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [filterDepartmentList, setFilterDepartmentList] = useState([]);

  const isSmallScreen = useMediaQuery("(max-width: 1590px)");

  const handleOpenDial = () => {
    setOpenDial(true);
  };

  const handleCloseDial = () => {
    setOpenDial(false);
  };

  useEffect(() => {
    if (totalUnitList) {
      setUnitList(
        [
          { unitId: 0, unitName: "Tất cả" },
          { unitId: -1, unitName: "Khác" },
        ].concat(
          totalUnitList.sort((a, b) => {
            return a.unitName.localeCompare(b.unitName, "vi");
          }),
        ),
      );
    }
  }, [totalUnitList]);

  useEffect(() => {
    if (Object.keys(departmentList).includes(selectedUnitId)) {
      setFilterDepartmentList(
        [
          { departmentId: 0, departmentName: "Tất cả" },
          { departmentId: -1, departmentName: "Khác" },
        ].concat(
          departmentList[selectedUnitId].sort((a, b) => {
            return a.departmentName.localeCompare(b.departmentName, "vi");
          }),
        ),
      );
    } else {
      setFilterDepartmentList([
        { departmentId: 0, departmentName: "Tất cả" },
        { departmentId: -1, departmentName: "Khác" },
      ]);
    }
  }, [departmentList, selectedUnitId]);

  const actions = [
    { icon: <Feed />, name: getLabel("TXT_FILL_FORM"), onClick: onClickCreateUser },
    { icon: <UploadFile />, name: getLabel("TXT_UPLOAD_FILE"), onClick: onClickImportUser },
  ];

  const onChangeUnit = e => {
    setSelectedUnitId(e.target.value);
    handleChangeUnitDropdown(e);
    handleToggleShowDepartment(e.target.value);
  };

  return (
    <Box className={classes.headerContainer}>
      <Box className={classes.searchBox}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          defaultValue={form.search}
          onChange={onChangeSearchInput}
          label={getLabel(LangConstant.P_ADD_NEW_USER)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />

        {isNotServiceAdmin && (
          <>
            <FormControl sx={{ ml: 1, width: 320 }}>
              <InputLabel>{getLabel("OBJ_USER_DETAIL.joinStatus")}</InputLabel>
              <Select
                size="small"
                value={form.state}
                onChange={onChangeStateDropdown}
                label={getLabel("OBJ_USER_DETAIL.joinStatus")}
              >
                {Object.values(userStates)
                  .sort((prev, next) => prev.order - next.order)
                  .map(userState => (
                    <MenuItem key={userState.state} value={userState.state}>
                      {userState.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl sx={{ ml: 1, width: 320 }}>
              <InputLabel>{getLabel("OBJ_USER_DETAIL.activeStatus")}</InputLabel>
              <Select
                size="small"
                value={form.status}
                onChange={onChangeStatusDropdown}
                label={getLabel("OBJ_USER_DETAIL.activeStatus")}
              >
                {Object.values(userStatus)
                  .sort((prev, next) => prev.order - next.order)
                  .map(userStatus => (
                    <MenuItem key={userStatus.status} value={userStatus.status}>
                      {userStatus.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl sx={{ ml: 1, width: 320 }}>
              <InputLabel>Đơn vị</InputLabel>
              <Select size="small" value={form.unitId} onChange={e => onChangeUnit(e)} label="Đơn vị">
                {unitList.map(data => (
                  <MenuItem key={data.unitId} value={data.unitId}>
                    {data.unitName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ ml: 1, width: 320 }}>
              <InputLabel>Phòng ban</InputLabel>
              <Select size="small" value={form.departmentId} onChange={handleChangeDepartmentDropdown} label="Phòng ban">
                {filterDepartmentList.map(data => (
                  <MenuItem key={data.departmentId} value={data.departmentId}>
                    {data.departmentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Box>
      <Box className={classes.topButtonContainer}>
        {isNotServiceAdmin && (
          <>
            <Button
              className={classes.deleteButton}
              disabled={checkedUsers.data.length === 0}
              onClick={onClickActiveButton}
              variant="contained"
              color="success"
            >
              {getLabel(LangConstant.L_ACTIVE_ACTION)}
            </Button>
            <Button
              className={classes.deleteButton}
              disabled={checkedUsers.data.length === 0}
              onClick={onClickInactiveButton}
              variant="contained"
              color="error"
            >
              {getLabel(LangConstant.L_INACTIVE_ACTION)}
            </Button>
          </>
        )}
        <Button
          className={classes.deleteButton}
          disabled={checkedUsers.data.length === 0}
          onClick={onClickDeleteButton}
          variant="contained"
        >
          {getLabel(LangConstant.TXT_DELETE)}
        </Button>
        <Box sx={{ position: "relative", width: "120px" }}>
          <Backdrop sx={{ zIndex: 1000 }} open={openDial} />
          <SpeedDial
            ariaLabel="SpeedDial Add User"
            onClose={handleCloseDial}
            onOpen={handleOpenDial}
            open={openDial}
            direction="down"
            className={classes.speedDial}
            icon={<CustomSpeedDialIcon />}
          >
            {actions.map(action => (
              <SpeedDialAction
                tooltipOpen
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.onClick}
                tooltipPlacement={isSmallScreen ? "right" : "left"}
              />
            ))}
          </SpeedDial>
        </Box>
      </Box>
    </Box>
  );
};

export default Heading;

const CustomSpeedDialIcon = () => {
  const { t: getLabel } = useTranslation([LangConstant.NS_COMMON, LangConstant.NS_LOGIN]);

  return (
    <SpeedDialIcon
      icon={
        <Box
          sx={{
            transform: "none !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
          }}
        >
          <Add />
          {getLabel("TXT_ADD_NEW")}
        </Box>
      }
    />
  );
};

const useStyles = makeStyles(theme => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    flexWrap: "wrap",
    gap: "16px",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  topButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "8px",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  },

  searchBox: {
    display: "flex",
    alignItems: "center",
    width: "1000px",

    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  },

  mainContainer: {
    marginBottom: 16,
  },

  speedDial: {
    position: "absolute",
    top: "-18px",
    right: 0,
    fontSize: "12px",

    "& > button": {
      width: "120px",
      height: "36px",
      borderRadius: "4px",
    },

    "& .MuiSpeedDialAction-staticTooltipLabel": {
      width: "200px !important",
    },
  },
}));