// Text Key
export const TXT_LOGIN = "TXT_LOGIN";
export const TXT_CHANGE_INFO = "TXT_CHANGE_INFO";
export const TXT_ADD_AVATAR = "TXT_ADD_AVATAR";
export const TXT_COMPANY_PROFILE = "TXT_COMPANY_PROFILE";
export const TXT_APP_COPYRIGHT = "TXT_APP_COPYRIGHT";
export const TXT_INVITE_AGAIN = "TXT_INVITE_AGAIN";
export const TXT_INVITED = "TXT_INVITED";
export const TXT_CONFIRM_REMOVE_USER_HEADING = "TXT_CONFIRM_REMOVE_USER_HEADING";
export const TXT_ADD_NEW_USER = "TXT_ADD_NEW_USER";
export const TXT_SELECTED_USER = "TXT_SELECTED_USER";
export const TXT_CONTACT_FOUND = "TXT_CONTACT_FOUND";
export const TXT_NOT_FOUND = "TXT_NOT_FOUND";
export const TXT_LOADING = "TXT_LOADING";
export const TXT_SERVER_EXPIRED = "TXT_SERVER_EXPIRED";
export const TXT_SERVER_ACTIVE = "TXT_SERVER_ACTIVE";
export const TXT_UPGRADE_LICENSE_PHONE_NUM = "TXT_UPGRADE_LICENSE_PHONE_NUM";
export const TXT_UPDATE_PASSWORD_SUCCESS = "TXT_UPDATE_PASSWORD_SUCCESS";
export const TXT_INFORMATION = "TXT_INFORMATION";
export const TXT_EDIT_INFO = "TXT_EDIT_INFO";
export const TXT_RESET_PASSWORD = "TXT_RESET_PASSWORD";
export const TXT_DETAILS_INFO = "TXT_DETAILS_INFO";
export const TXT_MODE = "TXT_MODE";
export const TXT_BRANCH_MODE = "TXT_BRANCH_MODE";
export const TXT_LOGIN_MODE = "TXT_LOGIN_MODE";
export const TXT_NOTIFICATION_ERROR_TITLE = "TXT_NOTIFICATION_ERROR_TITLE";
export const TXT_NOTIFICATION_ERROR_CONTENT = "TXT_NOTIFICATION_ERROR_CONTENT";
export const TXT_CONFIRM_ACTIVE_USER = "TXT_CONFIRM_ACTIVE_USER";
export const TXT_UPDATE_ERROR = "TXT_UPDATE_ERROR";
export const TXT_EMAIL_WRONG = "TXT_EMAIL_WRONG";
export const TXT_UPDATE_SUCCESS = "TXT_UPDATE_SUCCESS";
export const TXT_DELETE_FAIL = "TXT_DELETE_FAIL";
export const TXT_CONFIRM_DELETE_BRANCH = "TXT_CONFIRM_DELETE_BRANCH";
export const TXT_ADD_BRANCH = "TXT_ADD_BRANCH";
export const TXT_ACTIVE_ERROR = "TXT_ACTIVE_ERROR";
export const TXT_INACTIVE_ERROR = "TXT_INACTIVE_ERROR";
export const TXT_INVALID_DATE = "TXT_INVALID_DATE";
export const TXT_DATE_NULL = "TXT_DATE_NULL";
export const TXT_UPLOAD_DUPLICATE_DATA = "TXT_UPLOAD_DUPLICATE_DATA";
export const TXT_UPLOAD_MAX_USER_WRONG = "TXT_UPLOAD_MAX_USER_WRONG";
export const TXT_CONFIRM_CANCEL_CREATE_BRANCH = "TXT_CONFIRM_CANCEL_CREATE_BRANCH";
export const TXT_OF = "TXT_OF";
export const TXT_MORE_THAN = "TXT_MORE_THAN";
export const TXT_FAILURE_VALIDATION = "TXT_FAILURE_VALIDATION";
export const TXT_ADD_USER_SUCCESS = "TXT_ADD_USER_SUCCESS";
export const TXT_CHANGE_SMS_OTP = "TXT_CHANGE_SMS_OTP";
export const TXT_CHANGE_SMART_OTP = "TXT_CHANGE_SMART_OTP";
export const TXT_RESEND_OTP = "TXT_RESEND_OTP";
export const TXT_CHANGE_PHONE_NUMBER = "TXT_CHANGE_PHONE_NUMBER";
export const TXT_SYSTEM_ERROR_LIST = "TXT_SYSTEM_ERROR_LIST";
export const TXT_USER_ACTIVITY_LIST = "TXT_USER_ACTIVITY_LIST";
export const TXT_VERIFY_SMART_OTP_CONTENT = "TXT_VERIFY_SMART_OTP_CONTENT";
export const TXT_VERIFY_OTP = "TXT_VERIFY_OTP";
export const TXT_SMART_OTP_RETRY = "TXT_SMART_OTP_RETRY";
export const TXT_SMART_OTP_BLOCK = "TXT_SMART_OTP_BLOCK";
export const TXT_SMART_OTP_EXPIRED = "TXT_SMART_OTP_EXPIRED";
export const TXT_SMART_OTP_LIMIT_ERROR = "TXT_SMART_OTP_LIMIT_ERROR";
export const TXT_GRANT_ADMIN_PERMISSION = "TXT_GRANT_ADMIN_PERMISSION";
export const TXT_REMOVE_ADMIN_PERMISSION = "TXT_REMOVE_ADMIN_PERMISSION";
export const TXT_GENERATE_NEW_ADMIN_PASSWORD = "TXT_GENERATE_NEW_ADMIN_PASSWORD";
export const TXT_NEW_ADMIN_PASSWORD = "TXT_NEW_ADMIN_PASSWORD";
export const TXT_CONFIRM_ACTION = "TXT_CONFIRM_ACTION";
export const TXT_LOGIN_EXPIRED = "TXT_LOGIN_EXPIRED";
export const TXT_TITLE_UNIT_LIST = "TXT_TITLE_UNIT_LIST";
export const TXT_CREATE_DEPARTMENT = "TXT_CREATE_DEPARTMENT";
export const TXT_MEMBER_MANAGEMENT = "TXT_MEMBER_MANAGEMENT";
export const TXT_CREATE_CHANNEL = "TXT_CREATE_CHANNEL";
export const TXT_DELETE = "TXT_DELETE";
export const TXT_CREATE_UNIT = "TXT_CREATE_UNIT";
export const TXT_EDIT_UNIT = "TXT_EDIT_UNIT";
export const TXT_ENTER_UNIT_NAME = "TXT_ENTER_UNIT_NAME";
export const TXT_ENTER_DEPARTMENT_NAME = "TXT_ENTER_DEPARTMENT_NAME";
export const TXT_CREATE_DEPARTMENT_TITLE = "TXT_CREATE_DEPARTMENT_TITLE";
export const TXT_EDIT_DEPARTMENT = "TXT_EDIT_DEPARTMENT";
export const TXT_CONFIRM_DELETE_UNIT = "TXT_CONFIRM_DELETE_UNIT";
export const TXT_CONFIRM_DELETE_DEPARTMENT = "TXT_CONFIRM_DELETE_DEPARTMENT";
export const TXT_MEMBER_LIST = "TXT_MEMBER_LIST";
export const TXT_ADD_MEMBER = "TXT_ADD_MEMBER";
export const TXT_CREATE_POSITION_SUCCESS = "TXT_CREATE_POSITION_SUCCESS";
export const TXT_UPDATE_POSITION_SUCCESS = "TXT_UPDATE_POSITION_SUCCESS";
export const TXT_DELETE_POSITION_SUCCESS = "TXT_DELETE_POSITION_SUCCESS";
export const TXT_CONFIRM_DELETE_POSITION = "TXT_CONFIRM_DELETE_POSITION";
export const TXT_ADD_MEMBER_DIALOG = "TXT_ADD_MEMBER_DIALOG";
export const TXT_CONFIRM_DELETE_MEMBER = "TXT_CONFIRM_DELETE_MEMBER";
export const TXT_CREATE_CHANNEL_SUCCESS = "TXT_CREATE_CHANNEL_SUCCESS";
export const TXT_CREATE_CHANNEL_FAIL = "TXT_CREATE_CHANNEL_FAIL";
export const TXT_CHANNEL_DUPLICATED = "TXT_CHANNEL_DUPLICATED";
export const TXT_ADD_MEMBER_SUCCESS = "TXT_ADD_MEMBER_SUCCESS";
export const TXT_DELETE_MEMBER_SUCCESS = "TXT_DELETE_MEMBER_SUCCESS";
export const TXT_PHONE_DUPLICATED = "TXT_PHONE_DUPLICATED";
export const TXT_DATA_WRONG_FORMAT = "TXT_DATA_WRONG_FORMAT";
export const TXT_CREATE_ACCOUNT_FAIL = "TXT_CREATE_ACCOUNT_FAIL";
export const TXT_CREATE_ACCOUNT_SUCCESS = "TXT_CREATE_ACCOUNT_SUCCESS";
export const TXT_INVITE_FAIL = "TXT_INVITE_FAIL";
export const TXT_INVITE_SUCCESS = "TXT_INVITE_SUCCESS";
export const TXT_UPDATE_ROLE_ACCOUNT_SUCCESS = "TXT_UPDATE_ROLE_ACCOUNT_SUCCESS";
export const TXT_UPDATE_CONFERENCE_SUCCESS = "TXT_UPDATE_CONFERENCE_SUCCESS";

// Label Key
export const L_USERNAME = "L_USERNAME";
export const L_PASSWORD = "L_PASSWORD";
export const L_REMEMBER_ME = "L_REMEMBER_ME";
export const L_LOGIN_BUTTON = "L_LOGIN_BUTTON";
export const L_NEW_PASSWORD = "L_NEW_PASSWORD";
export const L_NEW_PASSWORD_CONFIRMATION = "L_NEW_PASSWORD_CONFIRMATION";
export const L_CONTINUE_BUTTON = "L_CONTINUE_BUTTON";
export const L_NEED_HELP_BUTTON = "L_NEED_HELP_BUTTON";
export const L_COMPANY_NAME = "L_COMPANY_NAME";
export const L_COMPANY_AVATAR = "L_COMPANY_AVATAR";
export const L_CHANGE_COMPANY_AVATAR_BUTTON = "L_CHANGE_COMPANY_AVATAR_BUTTON";
export const L_CONFIRM_BUTTON = "L_CONFIRM_BUTTON";
export const L_DISCARD_BUTTON = "L_DISCARD_BUTTON";
export const L_DELETE_USER_BUTTON = "L_DELETE_USER_BUTTON";
export const L_ADD_NEW_USER_BUTTON = "L_ADD_NEW_USER_BUTTON";
export const L_JOINED_STATUS = "L_JOINED_STATUS";
export const L_PENDING_STATUS = "L_PENDING_STATUS";
export const L_REFUSED_STATUS = "L_REFUSED_STATUS";
export const L_REMOVE_BUTTON = "L_REMOVE_BUTTON";
export const L_CANCEL_BUTTON = "L_CANCEL_BUTTON";
export const L_ADD_BUTTON = "L_ADD_BUTTON";
export const L_ACTIVE = "L_ACTIVE";
export const L_ACTIVATION_STATE = "L_ACTIVATION_STATE";
export const L_EXPIRED = "L_EXPIRED";
export const L_UPGRADE_LICENSE = "L_UPGRADE_LICENSE";
export const L_PASSWORD_LAST_UPDATE = "L_PASSWORD_LAST_UPDATE";
export const L_OLD_PASSWORD = "L_OLD_PASSWORD";
export const L_UPDATE_PASSWORD_BUTTON = "L_UPDATE_PASSWORD_BUTTON";
export const L_VIETNAMESE_LANGUAGE = "L_VIETNAMESE_LANGUAGE";
export const L_ENGLISH_LANGUAGE = "L_ENGLISH_LANGUAGE";
export const L_LANGUAGE = "L_LANGUAGE";
export const L_SAVE_BUTTON = "L_SAVE_BUTTON";
export const L_PUBLIC_MODE = "L_PUBLIC_MODE";
export const L_PRIVATE_MODE = "L_PRIVATE_MODE";
export const L_PASSWORD_MODE = "L_PASSWORD_MODE";
export const L_OTP_MODE = "L_OTP_MODE";
export const L_ACTIVE_USER = "L_ACTIVE_USER";
export const L_INACTIVE_USER = "L_INACTIVE_USER";
export const L_BRANCH_LIST = "L_BRANCH_LIST";
export const L_OK = "L_OK";
export const L_CREATE = "L_CREATE";
export const L_SWITCH_BRANCH = "L_SWITCH_BRANCH";
export const L_ACTIVE_ACTION = "L_ACTIVE_ACTION";
export const L_INACTIVE_ACTION = "L_INACTIVE_ACTION";
export const L_UPDATE = "L_UPDATE";
export const L_AGREE = "L_AGREE";
export const L_SAVE_INFO = "L_SAVE_INFO";
export const L_CANCEL_ADD_BRANCH = "L_CANCEL_ADD_BRANCH";
export const L_ADD_UNIT_BUTTON = "L_ADD_UNIT_BUTTON";
export const L_CANCEL = "L_CANCEL";
export const L_ALL = "L_ALL";
export const L_CLOSE = "L_CLOSE";
export const L_SELECT_UNIT = "L_SELECT_UNIT";

// Placeholder
export const P_USERNAME = "P_USERNAME";
export const P_PASSWORD = "P_PASSWORD";
export const P_NEW_PASSWORD = "P_NEW_PASSWORD";
export const P_COMPANY_NAME = "P_COMPANY_NAME";
export const P_ADD_NEW_USER = "P_ADD_NEW_USER";
export const P_OLD_PASSWORD = "P_OLD_PASSWORD";
export const P_SEARCH = "P_SEARCH";
export const P_SEARCH_UNIT = "P_SEARCH_UNIT";
export const P_SEARCH_USER_BY_NAME_PHONE_EMAIL = "P_SEARCH_USER_BY_NAME_PHONE_EMAIL";
export const P_SEARCH_USER_BY_NAME_EMAIL = "P_SEARCH_USER_BY_NAME_EMAIL";

// Error
export const ERR_OBJECT = "ERR_OBJECT";

// Object
export const OBJ_ROUTER_TITLE = "OBJ_ROUTER_TITLE";
export const OBJ_SIDEBAR_TITLE = "OBJ_SIDEBAR_TITLE";
export const OBJ_ERROR_MSG = "OBJ_ERROR_MSG";
export const OBJ_USER_DETAIL = "OBJ_USER_DETAIL";
export const OBJ_BRANCH_ERROR = "OBJ_BRANCH_ERROR";
export const OBJ_TABLE_ERROR = "OBJ_TABLE_ERROR";
export const OBJ_TABLE_BRANCH = "OBJ_TABLE_BRANCH";
export const OBJ_CONFIRM_RESET = "OBJ_CONFIRM_RESET";
export const OBJ_BRANCH_ERROR_TYPE = "OBJ_BRANCH_ERROR_TYPE";
export const OBJ_TABLE_USER_ACTIVITIES = "OBJ_TABLE_USER_ACTIVITIES";
export const OBJ_USER_ACTIVITIES = "OBJ_USER_ACTIVITIES";
export const OBJ_RANDOM_ADMIN_PASSWORD = "OBJ_RANDOM_ADMIN_PASSWORD";
export const OBJ_UNIT_LIST_TABLE = "OBJ_UNIT_LIST_TABLE";
export const OBJ_UNIT_DETAIL = "OBJ_UNIT_DETAIL";
export const OBJ_UNIT_ERROR = "OBJ_UNIT_ERROR";
export const OBJ_DEPARTMENT_DETAIL = "OBJ_DEPARTMENT_DETAIL";
export const OBJ_DEPARTMENT_ERROR = "OBJ_DEPARTMENT_ERROR";
export const OBJ_CHANNEL_DIALOG = "OBJ_CHANNEL_DIALOG";
export const OBJ_POSITION_ERROR = "OBJ_POSITION_ERROR";
export const OBJ_CONFERENCE_ERROR = "OBJ_CONFERENCE_ERROR";
export const OBJ_ROLE_ACCOUNT_ERROR = "OBJ_ROLE_ACCOUNT_ERROR";

// Format
export const FM_REMOVE_SINGLE_PERSON = "FM_REMOVE_SINGLE_PERSON";
export const FM_REMOVE_MULTIPLE_PEOPLE = "FM_REMOVE_MULTIPLE_PEOPLE";
export const FM_ACTIVE_SINGLE_PERSON = "FM_ACTIVE_SINGLE_PERSON";
export const FM_ACTIVE_MULTIPLE_PERSON = "FM_ACTIVE_MULTIPLE_PERSON";
export const FM_UPLOAD_PHONE_FORMAT_WRONG = "FM_UPLOAD_PHONE_FORMAT_WRONG";
export const FM_COUNTDOWN_OTP = "FM_COUNTDOWN_OTP";

// Default language code
export const DEFAULT_LANGUAGE = "vi";

// Namespace Key
export const NS_COMMON = "common";
export const NS_LOGIN = "login";

// Common languages
export const ARR_LANGUAGES = [
  { lang: "Tiếng Việt", code: "vi", label: L_VIETNAMESE_LANGUAGE },
  { lang: "English", code: "en", label: L_ENGLISH_LANGUAGE },
];

// Dùng cho cả channel
export const BRANCH_MODE = [
  { label: L_PUBLIC_MODE, code: 0 },
  { label: L_PRIVATE_MODE, code: 1 },
];

export const LOGIN_MODE = [
  { label: L_OTP_MODE, code: 0 },
  { label: L_PASSWORD_MODE, code: 1 },
];

