import React, { Fragment, useState, useRef, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Box, Typography } from "@mui/material";
import { LangConstant } from "const";
import { useUserList, useConferenceList, useUpdateRoleAccount, useUpdateConference } from "hooks";
import { PageTitle } from "components";
import { ActionDialog, Datatable, Heading, EditAppDialog } from "./components";

const ConferenceManagement = () => {
  const { t: getLabel } = useTranslation();
  const titleObjectContent = getLabel(LangConstant.OBJ_SIDEBAR_TITLE, { returnObjects: true });
  const {
    pagination,
    conferences,
    handleChangePage,
  } = useConferenceList();

  const { updateRoleAccount } = useUpdateRoleAccount();

  const { updateConference } = useUpdateConference();

  const [searchQuery, setSearchQuery] = useState("");

  const filteredConferences = useMemo(() => {
    return conferences.filter(conference =>
      conference.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [conferences, searchQuery]);

  const {
    form,
    setForm,
    filterUsers,
    currentPageUsers,
    pagination: userPagination,
    handleChangeSearchInput: handleUserSearch,
    handleChangePageWithStatusActive,
  } = useUserList();
  const [actionDialogConfig, setActionDialogConfig] = useState({ open: false, conference: null, isEdit: false });
  const [editAppDialogConfig, setEditAppDialogConfig] = useState({ open: false, conference: null });

  const checkedUsersRef = useRef([])
  const handleOpenAddAdminDialog = useCallback((conference) => {
    setForm((prev) => ({ ...prev, state: 1 }));
    setActionDialogConfig({ open: true, conference, isEdit: false });
  }, [setForm]);

  const handleOpenEditAdminDialog = useCallback((conference) => {
    setForm((prev) => ({ ...prev, state: 1 }));
    const adminUser = filterUsers.filter(user => conference?.adminAccountIds.includes(user.accountId));
    checkedUsersRef.current = adminUser;
    setActionDialogConfig({ open: true, conference, isEdit: true });
  }, [setForm, filterUsers]);

  const handleCloseActionDialog = () => {
    setForm((prev) => ({ ...prev, search: "" }));
    setActionDialogConfig({ open: false, conference: null, isEdit: false });
  };

  const handleCloseEditAppDialog = () => {
    setEditAppDialogConfig({ open: false, conference: null });
  };

  const handleOpenEditAppDialog = conference => {
    setForm((prev) => ({ ...prev, state: 1 }));
    setEditAppDialogConfig({ open: true, conference: conference });
  };

  const handleUpdateAccountRole = (form) => {
    updateRoleAccount({
      serviceId: form.serviceId,
      accountIds: form.accountIds,
      onClose: handleCloseActionDialog,
    });
  };

  const handleUpdateConference = (form) => {
    updateConference({
      conferenceId: form.conferenceId,
      title: form.title,
      description: form.description,
      onClose: handleCloseActionDialog,
    });
  };


  return (
    <Fragment>
      <PageTitle>{getLabel(titleObjectContent.conferenceManagement)}</PageTitle>
      <Paper sx={{ px: 2, py: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "20px", marginBottom: "10px" }}> Danh sách ứng dụng </Typography>
        </Box>
        <Heading
          searchInput={searchQuery}
          onChangeSearchInput={setSearchQuery}
        />

        <Datatable
          conferences={filteredConferences}
          pagination={pagination}
          onChangePage={handleChangePage}
          onClickAddAdmin={handleOpenAddAdminDialog}
          onClickEditAdmin={handleOpenEditAdminDialog}
          onClickEdit={handleOpenEditAppDialog}
        />
        <ActionDialog
          form={form}
          open={actionDialogConfig.open}
          conference={actionDialogConfig.conference}
          onClose={handleCloseActionDialog}
          onChangeSearchInput={handleUserSearch}
          isEdit={actionDialogConfig.isEdit}
          checkedUsers={checkedUsersRef.current}
          handleUpdateAccountRole={handleUpdateAccountRole}
          pagination={userPagination}
          currentPageUsers={currentPageUsers}
          onChangePage={handleChangePageWithStatusActive}
        />
        <EditAppDialog
          open={editAppDialogConfig.open}
          conference={editAppDialogConfig.conference}
          onClose={handleCloseEditAppDialog}
          onUpdate={handleUpdateConference}
        />
      </Paper>
    </Fragment>
  );
};

export default ConferenceManagement;
