import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LangConstant } from "const";

const Datatable = ({ conferences, pagination, onChangePage, onClickAddAdmin, onClickEditAdmin }) => {
  const { t: getLabel } = useTranslation();
  const classes = useStyles();
  return (
    <Fragment>
      <TableContainer sx={{ my: 4, height: 500 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} className={classes.boxTable}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "30%", fontWeight: "bold" }}>{getLabel("TXT_APPLICATION_NAME")}</TableCell>
              <TableCell sx={{ width: "45%", fontWeight: "bold" }}>{getLabel("TXT_DESCRIPTION")}</TableCell>
              <TableCell sx={{ width: "15%", fontWeight: "bold" }}>{getLabel("TXT_ADMINISTRATOR")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conferences.length > 0 ? (
              conferences.map((conference, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.name} title={conference.name}>
                    {conference.name}
                  </TableCell>
                  <TableCell className={classes.name} title={conference.description}>
                    {conference.description}
                  </TableCell>
                  <TableCell className={classes.name} title={conference.adminAccountIds?.length || getLabel(LangConstant.L_ADD_BUTTON)}>
                    {conference.adminAccountIds ? (
                      <span className={classes.addButton} onClick={() => onClickEditAdmin(conference)}>
                        {conference.adminAccountIds.length}
                      </span>
                    ) : (
                      <span className={classes.addButton} onClick={() => onClickAddAdmin(conference)}>
                        {getLabel(LangConstant.L_ADD_BUTTON)}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <small>
                    <i>{getLabel(LangConstant.TXT_NOT_FOUND)}</i>
                  </small>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination.total > 1 && (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "24px 0" }}>
          <Pagination
            count={pagination.total}
            onChange={onChangePage}
            page={pagination.current}
            className={classes.pagination}
            color="primary"
            variant="outlined"
            shape="rounded"
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Fragment>
  );
};

export default Datatable;

const useStyles = makeStyles(() => ({
  pagination: {
    display: "flex",
    justifyContent: "center",
  },

  boxTable: {
    whiteSpace: "nowrap",
    tableLayout: "fixed",
  },

  name: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  addButton: {
    color: "#007BFF", // Màu xanh sáng hơn
    cursor: "pointer",
    textDecoration: "none", // Bỏ gạch chân mặc định
    "&:hover": {
      textDecoration: "underline", // Chỉ hiển thị gạch chân khi hover
      color: "#0056b3", // Màu xanh đậm hơn khi hover
    },
  },
}));
